import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";


export default function Home() {
  return (
    <Layout>
        <Helmet>
          <title>Upload Your Photos</title>
          <meta name="description" content="Upload a picture, share the details, and watch as your act of kindness has a positive impact on the community." />
      </Helmet>
      <iframe 
        id="uhob5k?web=1&amp;entry=1_0_offer" 
        title="Upload your photos to our contest!" 
        scrolling="yes" 
        width="100%"
        height="100vh"
        class="uhob5k?web=1&amp;entry=1_0_offer" 
        src="https://woobox.com/uhob5k?web=1&amp;entry=1?embed=1&amp;plugin=%257B%2522pid%2522%253A%2522uhob5k%253Fweb%253D1%2526entry%253D1_0%2522%252C%2522fid%2522%253A%2522offer%2522%257D" 
        style={{'minWidth': '100%', 'height': 'calc(100vh - 73px)', 'border': 'none', 'borderRadius': '3px', 'transition': 'height 0.2s ease-out 0s'}}  />
    </Layout>
  )
};
